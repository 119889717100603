<template>
  <div class="login public-login">
    <h2 class="page-title">{{ LP.lang_openplatform_login }}</h2>
    <el-form ref="loginForm" :model="form" :rules="rules" @submit.native.prevent>
      <el-form-item :label="LP.lang_email" prop="email">
        <el-input v-model="form.email" class="text" clearable @keyup.native.enter="handleLogin" />
      </el-form-item>
      <el-form-item :label="LP.lang_pssword" prop="password">
        <router-link class="forgot-password" to="/login/forgotpassword">{{ LP.lang_forgot_password }}</router-link>
        <el-input v-model="form.password" class="pwd" :type="pwdType" @keyup.native.enter="handleLogin">
          <span slot="suffix" class="el-input__suffix" @click="showPwd">
            <span class="el-input__suffix-inner">
              <i class="el-icon-view"></i>
            </span>
          </span>
        </el-input>
      </el-form-item>
      <el-form-item prop="verificationCode">
        <el-input v-model="form.verificationCode" class="verification-code" :placeholder="LP.lang_enter_verification" @keyup.enter.native="handleLogin" />
        <img :src="src" onClick="this.src=this.src+'?'" style="cursor:pointer;" />
      </el-form-item>
    </el-form>
    <el-button :loading="loading" class="login-button" type="primary" @click="handleLogin">{{ LP.lang_openplatform_login }}</el-button><br />
    <router-link to="/register" class="register">{{ LP.lang_register_to_partner }}</router-link>
    <!-- 弹窗 -->
    <el-dialog
      title=""
      :visible.sync="loginDialog"
      :before-close="handleConfirm"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      custom-class="login-tip-dialog"
      :show-close="false"
    >
      <div class="tip-box">
        <span class="iconfont icon-cuowu"></span>
        <p class="tip-text">{{ loginStatusText }}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleConfirm">{{ LP.lang_know }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { _axios } from '@/plugins/axios.js'
import { JSEncrypt } from 'jsencrypt'
export default {
  name: 'Login',
  data() {
    return {
      form: { // 登录信息
        email: '',
        password: '',
        verificationCode: ''
      },
      rules: {
        email: [
          { required: true, message: this.LP.lang_enter_email, trigger: 'blur' },
          { type: 'email', message: this.LP.lang_enter_correct_email, trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.LP.lang_enter_password, trigger: 'blur' }
        ],
        verificationCode: [
          { required: true, message: this.LP.lang_enter_verification, trigger: 'blur' }
        ]
      },
      pwdType: 'password',
      loading: false,
      src: _axios.defaults.baseURL + '/user/verification_code',
      loginDialog: false,
      loginStatusText: ''
    }
  },
  methods: {
    // 密码加密处理
    encryptedData(data) {
      var publicKey = `-----BEGIN PUBLIC KEY-----
        MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyzTUgEniEBho5r+C3/Sz
        +J+cWhKx26zDuD2W9/Dm8mxn1sCK3eEu3SswQ4c/90wIykjBocnw50cQagXG4K8n
        1Fh3Px+1vMSwxzlTY+Sepg3MSFylZ94Fth9bxAAnR4Wtao37BJkWu46rttQiM1PC
        MOKCWS1vdexxKqgTDkgmP2IUUekzyUXBYL5TU5TOrgwfF3lmx3PiLzOoEmDAXru2
        jtjorIlU2vhepJ6k8qB3Qo78gFxwh1ZYaS3vysrm0h/5AnWfN73+xr1fgKA/iT39
        VhRBoi/jacJekOl1Rsg8gEvl4pI33op8U/+7YjZt1nfxq8pNcQjNT3NttBnWmDuE
        BwIDAQAB
        -----END PUBLIC KEY-----`
      var encrypt = new JSEncrypt()
      encrypt.setPublicKey(publicKey) // 设置公钥
      const encrypted = encrypt.encrypt(data) // 密码加密
      return encrypted
    },
    // 密码展示或隐藏
    showPwd() {
      this.pwdType = (this.pwdType === 'password' ? 'text' : 'password')
    },
    // 登录
    handleLogin() {
      const passwordTag = this.encryptedData(this.form.password)
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store
            .dispatch('login/getLogin', {
              email: this.form.email,
              password: passwordTag,
              verificationCode: this.form.verificationCode
            })
            .then((res) => {
              if (res.status === '00') {
                this.$message.success(res.message)
                this.$router.push('/')
              } else {
                if (res.state_type === '4') {
                  this.loginStatusText = res.message
                  this.loginDialog = true
                } else {
                  this.$message.error(res.message)
                }
              }
              this.src = this.src + '?'
            })
            .catch((err) => {
              this.$message.error(err.data.message)
              this.src = this.src + '?'
            })
          this.loading = false
        } else {
          this.src = this.src + '?'
          return false
        }
      })
    },
    // 确定
    handleConfirm() {
      this.loginDialog = false
      this.$router.push('/')
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/styles/pages/login.less";
.login {
  .page-title {
    margin-bottom: 28px;
  }
  .register {
    margin-top: 24px;
    display: inline-block;
    font-size: 16px;
    color: @theme;
    line-height: 21px;
  }
  .verification-code {
    width: calc(100% - 100px);
    margin-right: 20px;
  }
  /deep/ .login-tip-dialog {
    width: 92%;
    max-width: 420px;
    .tip-box {
      display: flex;
      align-items: center;
      .iconfont {
        color: red;
        font-size: 18px;
        margin-right: 6px;
      }
      .tip-text {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  @media screen and (max-width: 768px){
    .page-title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
    }
    /deep/.el-form-item__label {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 4px;
    }
    /deep/.el-form-item {
      .forgot-password{
        font-size: 14px;
        line-height: 19px;
      }
    }
    .register {
      margin-top: 14px;
      font-size: 14px;
      line-height: 19px;
    }
  }
}
</style>

<template>
  <div class="forgot-password public-login">
    <h2 class="page-title">{{ LP.lang_change_password }}</h2>
    <p class="page-desc">{{ LP.lang_change_password_description }}</p>
    <!-- 发送验证码 -->
    <!-- <template v-if="isSendEmail">
      <el-form ref="emailForm" :model="emailForm" :rules="emailRules" @submit.native.prevent>
        <el-form-item :label="LP.lang_email" prop="email">
          <el-input v-model="emailForm.email" class="text" clearable @keyup.native.enter="handleSend" />
        </el-form-item>
      </el-form>
      <el-button :loading="sendLoading" class="login-button" type="primary" @click="handleSend">{{ LP.lang_send }}</el-button>
    </template> -->
    <!-- 修改密码 -->
    <el-form ref="changeForm" :model="changeForm" :rules="changeRules" @submit.native.prevent>
      <el-form-item :label="LP.lang_email" prop="email">
        <span class="telno">{{ LP.lang_email_note }}</span>
        <el-input v-model="changeForm.email" placeholder="Email" :disabled="emailDisabled" />
        <!-- 邮箱验证按钮 -->
        <div v-if="!isSuccessVerify" class="email-verify-box" @click="openVerifyPopover()">
          <span class="verify-btn">{{ LP.lang_verify }}</span>
        </div>
        <el-button
          v-else
          class="email-time-btn"
          type="primary"
        >
          <span>{{ count }}s</span>
        </el-button>
      </el-form-item>

      <el-form-item :label="LP.lang_verification_code" prop="verify">
        <el-input v-model="changeForm.verify" class="pwd" clearable @keyup.native.enter="handleChange" />
      </el-form-item>
      <el-form-item :label="LP.lang_new_password" prop="password">
        <el-input v-model="changeForm.password" class="pwd" clearable type="password" @keyup.native.enter="handleChange" />
        <span class="check-tips">{{ LP.lang_strong_password }}</span>
      </el-form-item>
      <el-form-item :label="LP.lang_reenter_new_password" prop="repeat">
        <el-input v-model="changeForm.repeat" class="pwd" type="password" clearable @keyup.native.enter="handleChange" />
      </el-form-item>
    </el-form>
    <el-button :loading="changeLoading" class="login-button" type="primary" @click="handleChange">{{ LP.lang_save_changes }}</el-button>
    <!-- 邮箱验证弹窗 -->
    <slide-dialog
      :email-dialog-visible="emailDialogVisible"
      :email-error-dialog-visible="emailErrorDialogVisible"
      :email-error-text="emailErrorText"
      @emailSuceess="emailSuceess"
      @closeEmailError="closeEmailError"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import { getCodeApi, getChangePasswordApi, emailVerifyApi } from '@/api/login.js'
import SlideDialog from '@/components/public/SlideDialog.vue'
import { getCookie } from '@/utils/cookie'
export default {
  name: 'Login',
  components: {
    SlideDialog
  },
  data() {
    const eamilPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter the email address'))
      } else {
        callback()
      }
    }
    var pwdRepeat = (rule, value, callback) => {
      if (value !== this.changeForm.password) {
        callback(new Error(this.LP.lang_two_passwords_inconsistent))
      } else {
        callback()
      }
    }
    // Password
    var password = (rule, value, callback) => {
      // 必须包含8-32位字符（大小写英文/数字/符号）三种组合
      const passWordReg = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,32}/
      if (value === '') {
        return callback(new Error(this.LP.lang_enter_password))
      } else {
        if (passWordReg.test(value)) {
          callback()
        } else {
          callback(new Error(this.LP.lang_enter_correct_passWord))
        }
      }
    }
    return {
      isSendEmail: true,
      emailForm: { // 获取验证码
        email: ''
      },
      emailRules: {
        email: [
          { required: true, message: this.LP.lang_enter_email, trigger: 'blur' },
          { type: 'email', message: this.LP.lang_enter_correct_email, trigger: 'blur' }
        ]
      },
      sendLoading: false,
      changeForm: { // 修改密码
        email: '',
        verify: '',
        password: '',
        repeat: ''
      },
      changeRules: {
        email: [
          { required: true, validator: eamilPass, trigger: 'blur' },
          {
            type: 'email',
            message: 'Please enter the correct email address',
            trigger: 'blur,change'
          }
        ],
        verify: [{ required: true, message: this.LP.lang_change_password_detail, trigger: 'blur' }],
        password: [{ required: true, validator: password, trigger: 'blur' }],
        repeat: [
          { required: true, message: this.LP.lang_password_again, trigger: 'blur' },
          { validator: pwdRepeat, trigger: 'blur' }
        ]
      },
      changeLoading: false,
      emailDialogVisible: false,
      isSuccessVerify: false,
      emailTimer: null,
      count: '',
      emailErrorDialogVisible: false,
      emailErrorText: '',
      emailDisabled: false
    }
  },
  created() {
    this.changeForm.email = getCookie('email') ? getCookie('email') : ''
    this.emailDisabled = !!getCookie('email')
  },
  mounted() {
    // 监听页面宽度变化
    window.onresize = () => {
      if (document.body.clientWidth <= 480) {
        this.slideWidth = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 64
      }
    }
    this.$nextTick(function() {
      if (document.body.clientWidth <= 480) {
        this.slideWidth = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 64
      }
    })
  },
  methods: {
    // 发送验证码
    handleSend() {
      this.$refs.emailForm.validate((valid) => {
        if (valid) {
          this.sendLoading = true
          getCodeApi({
            email: this.emailForm.email
          }).then((res) => {
            this.$message.success(this.LP.lang_verification_code_sent_successfully)
            this.isSendEmail = false
          }).catch((err) => {
            this.$message.error(err.data.data)
          })
          this.sendLoading = false
        }
      })
    },
    // 修改密码
    handleChange() {
      this.$refs.changeForm.validate((valid) => {
        if (valid) {
          this.changeLoading = true
          getChangePasswordApi({
            email: this.changeForm.email,
            code: this.changeForm.verify,
            password: this.changeForm.password,
            confirmPassword: this.changeForm.repeat,
            menu: getCookie('email') ? 'change' : 'forget'
          }).then((res) => {
            if (res.status === '00') {
              if (getCookie('email')) {
                this.$store.dispatch('login/logout')
              }
              this.$message.success(res.message)
              this.$router.push('/login')
            } else {
              this.$message.error(res.message)
            }
          }).catch((err) => {
            this.$message.error(err.data.message)
          })
          this.changeLoading = false
        }
      })
    },
    // 打开邮箱验证弹窗
    openVerifyPopover() {
      const regEmail = /^[\w]+([-_.][\w]+)*@([-_\w]+\.)+[a-z]{2,14}$/i
      if (regEmail.test(this.changeForm.email)) {
        this.emailDialogVisible = true
      } else {
        this.$refs.changeForm.validateField('email')
        this.emailDialogVisible = false
      }
    },
    // 关闭验证弹框
    closeDialog() {
      this.emailDialogVisible = false
    },
    closeEmailError() {
      this.emailErrorDialogVisible = false
    },
    // 邮箱验证成功
    emailSuceess() {
      emailVerifyApi({
        type: 'RetrievePassword',
        email: this.changeForm.email
      }).then(res => {
        if (res.status === '43') {
          this.emailDialogVisible = false
          this.emailErrorText = res.data
          this.emailErrorDialogVisible = true
        } else {
          this.emailDialogVisible = false
          this.emailErrorText = this.LP.lang_verification_successful_email
          this.emailErrorDialogVisible = true
          if (this.emailErrorDialogVisible) {
            setTimeout(() => {
              this.emailErrorDialogVisible = false
              this.isSuccessVerify = true
              // 倒计时
              const TIME_COUNT = 180
              if (!this.emailTimer) {
                this.count = TIME_COUNT
                this.emailTimer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--
                  } else {
                    clearInterval(this.emailTimer)
                    this.emailTimer = null
                    this.isSuccessVerify = false
                  }
                }, 1000)
              }
            }, 3000)
          }
        }
      }).catch(error => {
        this.emailDialogVisible = false
        if (error.response && error.response.status === 401) {
          this.emailErrorText = error.response.data.data
          this.emailErrorDialogVisible = true
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/styles/pages/login.less";
.forgot-password {
  .page-title {
    margin-bottom: 16px;
  }
  .page-desc {
    margin-bottom: 32px;
  }
  .check-tips {
    display: block;
    line-height: 24px;
  }
  .el-form {
    .email-verify-box {
      font-size: 14px;
      line-height: 18px;
      border-radius: 4px;
      color: #FFFFFF;
      border: 1px solid @theme;
      background: @theme;
      position: absolute;
      right: 6px;
      top: 52%;
      padding: 8px 16px;
      cursor: pointer;
    }
    .email-time-btn {
      font-size: 14px;
      line-height: 18px;
      padding: 5px 16px;
      width: auto;
      position: absolute;
      top: 54%;
      right: 6px;
      color: @theme;
      border: 1px solid @theme;
      background: transparent;
    }
    .telno {
      color: #999999;
      line-height: 22px;
    }
  }
}
</style>

<style lang="less">
.forgot-password {
  .el-form-item__label {
    line-height: 1 !important;
    padding: 11px 12px 11px 0 !important;
    margin-bottom: 0 !important;
  }
  .el-input__inner {
    padding: 0 96px 0 15px !important;
  }
}
</style>
